import React, {useEffect, useState} from 'react';
import './App.css';
import {from} from "linq-to-typescript";
import {getChangelogDisplayData} from "./DataCollector";
import {ChangelogDisplay} from "./interfaces/ChangelogDisplay";
import {ChangelogEntryType} from "./interfaces/ChangelogEntryType";
import {Modal, Table} from "react-bootstrap";
import {toRangDisplayTitle} from "./interfaces/RangGroup";
import TeamTabelle from "./TeamTabelle";

function App() {
  const [error, setError] = useState<Error | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [changelog, setChangelog] = useState<ChangelogDisplay[]>([]);
  const [displayUser, setDisplayUser] = useState<ChangelogDisplay | null>(null);

  const changelogAktivMatch = (x: ChangelogDisplay): boolean => {
      const current = from(x.history).last();
      return current.changelogEntryType !== ChangelogEntryType.QUIT && !current.isFaulty;
  };
  const changelogAktiv = from(changelog).where(x => changelogAktivMatch(x)).toArray();
  const changelogInaktiv = from(changelog).where(x => !changelogAktivMatch(x)).toArray();

  useEffect(() => {
      getChangelogDisplayData()
        .then(
            (result) => {
              setIsLoaded(true);
              setChangelog(result);
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
        )
        .catch(error => {
          setIsLoaded(true);
          setError(error);
        });
  }, []);

  const showUserDetails = (user: ChangelogDisplay) => setDisplayUser(user);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
        <React.Fragment>
            <h1 style={{textAlign: "center", marginTop: "10px"}}>Cytooxien Teamzeit Überblick</h1>
            <TeamTabelle changelog={changelogAktiv} funcShowUser={showUserDetails} displayAktiv={true}/>
            <h2 style={{textAlign: "center"}}>Ehemalige Team-Mitglieder</h2>
            <TeamTabelle changelog={changelogInaktiv} funcShowUser={showUserDetails} displayAktiv={false}/>
            <Modal show={displayUser != null} onHide={() => setDisplayUser(null)}>
                {displayUser &&
                    <React.Fragment>
                        <Modal.Header closeButton>
                            <Modal.Title>Changelog {displayUser.currentName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            UUID: {displayUser.uuid}<br/>
                            Rang: {displayUser.rang}<br/>
                            Gruppe: {toRangDisplayTitle(displayUser.rangGroup)}<br/>
                            Team-Join: {displayUser.teamJoin.toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" })}<br/>
                            Team-Time: {displayUser.teamTime?.years} Jahre {displayUser.teamTime?.months} Monate {displayUser.teamTime?.days} Tage<br/>
                            <br/>
                            <Table striped bordered hover size="sm" variant="dark">
                                <thead>
                                <tr>
                                    <th>Typ</th>
                                    <th>Zeitpunkt</th>
                                    <th>Neuer Rang</th>
                                    <th>Name</th>
                                </tr>
                                </thead>
                                <tbody>
                                {displayUser.history.map((x, index) => (
                                    <tr key={index}>
                                        <td>{x.changelogEntryType}</td>
                                        <td>{x.localDate.toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" })}</td>
                                        <td>{x.rang}</td>
                                        <td>{x.name}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Modal.Body>
                    </React.Fragment>
                }
            </Modal>
        </React.Fragment>
    );
  }
}

export default App;
