import {Table} from "react-bootstrap";
import {from} from "linq-to-typescript";
import {toRangDisplayTitle} from "./interfaces/RangGroup";
import {ChangelogEntryType, toChangelogTypIcon} from "./interfaces/ChangelogEntryType";
import React, {createRef, useEffect} from "react";
import {ChangelogDisplay} from "./interfaces/ChangelogDisplay";
import $ from "jquery";
import "datatables.net";

function TeamTabelle(props: {changelog: ChangelogDisplay[], funcShowUser: (x: ChangelogDisplay) => void, displayAktiv: boolean}) {
    const anniversaryMonthStyle = { "--bs-table-bg": "#0c3c25", "--bs-table-striped-bg": "#1e583d", "--bs-table-hover-bg": "#089151" } as React.CSSProperties;
    const newStyle = { "--bs-table-bg": "#3c1353", "--bs-table-striped-bg": "#411e58", "--bs-table-hover-bg": "#630891" } as React.CSSProperties;
    const refTable = createRef<HTMLTableElement>();

    const getRowStyle = (user: ChangelogDisplay) => {
        return !props.displayAktiv ? undefined :
            user.teamTime?.months !== 0 ? undefined :
            user.teamTime?.years === 0 ? newStyle : anniversaryMonthStyle;
    }

    useEffect(() => {
        if (refTable.current) {
            setTimeout(() => {
                if (!$.fn.dataTable.isDataTable(refTable.current!)) {
                    $(refTable.current!).DataTable({
                        paging: false,
                        searching: false,
                        order: [ [ 0, "asc"], [ 1, "asc" ]],
                        orderClasses: true,
                        info: false
                    });
                }
            }, 250);
        }
    }, [refTable]);

    return (
        <Table striped bordered hover size="sm" variant="dark" ref={refTable}>
            <thead>
            <tr>
                <th>Rang</th>
                <th>Ingame-Name</th>
                <th>Im Team seit</th>
                <th>Zeit im Team: Jahre</th>
                <th>Monate</th>
                <th>Tage</th>
                <th>Team verlassen/betreten</th>
            </tr>
            </thead>
            <tbody>
            {from(props.changelog).groupBy(x => x.rangGroup ?? "").orderBy(x => x.key).toArray().map(rang =>
                from(rang).orderBy(x => x.currentName?.toLowerCase()).toArray().map(user => (
                    <tr key={`${rang.key}_${user.uuid}`} onClick={x => props.funcShowUser(user)} style={getRowStyle(user)}>
                        <td data-order={rang.key}>
                            {toRangDisplayTitle(user.rangGroup)}
                        </td>
                        <td>
                            {user.currentName}
                        </td>
                        <td data-order={user.teamJoin.toISOString()}>
                            {user.teamJoin.toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" })}
                        </td>
                        <td data-order={`${user.teamTime?.years.toString().padStart(2, '0')}${user.teamTime?.months.toString().padStart(2, '0')}${user.teamTime?.days.toString().padStart(2, '0')}`}>
                            {user.teamTime?.years}
                        </td>
                        <td data-order={`${user.teamTime?.months.toString().padStart(2, '0')}${user.teamTime?.days.toString().padStart(2, '0')}`}>
                            {user.teamTime?.months}
                        </td>
                        <td>
                            {user.teamTime?.days}
                        </td>
                        <td>
                            {user.history.map((history, index) => (
                                <span key={index} style={{color: (history.changelogEntryType === ChangelogEntryType.JOIN ? "#03d003" : history.changelogEntryType === ChangelogEntryType.QUIT ? "red" : "yellow"), paddingRight: "4px"}}>
                                            {toChangelogTypIcon(history.changelogEntryType)}&nbsp;
                                    {history.localDate.toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" })}
                                </span>
                            ))}
                        </td>
                    </tr>
                ))
            )}
            </tbody>
        </Table>
    );
}

export default TeamTabelle;
