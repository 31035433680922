export enum ChangelogEntryType {
    JOIN = "JOIN",
    QUIT = "QUIT",
    RANKUP = "RANKUP",
    RANKDOWN = "RANKDOWN"
}

export function toChangelogTypIcon(type: ChangelogEntryType) {
    switch (type) {
        case ChangelogEntryType.JOIN:
            return "[+]";
        case ChangelogEntryType.QUIT:
            return "[-]";
        case ChangelogEntryType.RANKUP:
            return "[⇧]";
        case ChangelogEntryType.RANKDOWN:
            return "[⇩]";
        default:
            return "";
    }
}
