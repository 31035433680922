import {ChangelogDisplay} from "./interfaces/ChangelogDisplay";
import {History} from "./interfaces/History";
import {from} from "linq-to-typescript";
import {RangGroup} from "./interfaces/RangGroup";
import {ChangelogEntryType} from "./interfaces/ChangelogEntryType";

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const backendUrl = development ? "http://localhost:25018/api" : "/api";
const today = new Date();

export async function getChangelogDisplayData(): Promise<ChangelogDisplay[]>
{
    const personTask = fetch(`${backendUrl}?getdata=person`);
    const responseHistoryTask = fetch(`${backendUrl}?getdata=history`);
    const person: { [uuid: string]: string } = await (await personTask).json();
    const history: History[] = await (await responseHistoryTask).json();
    const output: ChangelogDisplay[] = [];

    history?.forEach(x => {
        // Fix Date Parsing
        x.localDate = new Date(x.localDate);
        // Fix Rang
        x.rang = !x.rang || x.rang.length === 0 ? "Spieler" : toTitleCase(x.rang)
    })

    from(history).groupBy(x => x.uuid).toArray().forEach(x => {
        const history = x.orderBy(x => x.localDate.getTime());
        const first = x.first();
        const current = x.last();
        const displayTeamTime = !current.isFaulty;

        // IF Current Rang Spieler than use rang of History Before
        const rangGroupRang = (current.rang === "Spieler" && history.count() > 1 ? history.reverse().skip(1).first().rang : current.rang);

        output.push({
            rangGroup: getRangGroup(rangGroupRang),
            rang: current.rang,
            currentName: person[current.uuid],
            uuid: current.uuid,
            teamJoin: first.localDate,
            teamTime: displayTeamTime ? calcTeamTime(history.toArray()) : null,
            history: history.toArray()
        });
    });

    return output;
}

function getRangGroup(rang: string | null): RangGroup | null {
    const checkRang = rang?.toLowerCase();
    if (checkRang?.includes("owner")) {
        return RangGroup.Owner;
    }
    if (checkRang?.includes("administrator")) {
        return RangGroup.Admin;
    }
    if (checkRang?.includes("entwickler")) {
        return RangGroup.Entwickelnde;
    }
    if (checkRang?.includes("moderator")) {
        return RangGroup.Moderierende;
    }
    if (checkRang?.includes("supporter")) {
        return RangGroup.Supportende;
    }
    if (checkRang?.includes("content")) {
        return RangGroup.Contents;
    }
    if (checkRang?.includes("architekt") || checkRang?.includes("bauteam")) {
        return RangGroup.Architekten;
    }
    if (checkRang?.includes("spieler")) {
        return RangGroup.Spielende;
    }

    return null;
}

function calcTeamTime(history: History[]): {
    years: number,
    months: number,
    days: number
} {
    let teamTimeMillis = 0;
    let lastJoinTime = 0;
    history.forEach(x => {
        const currentMillis = x.localDate.getTime();
        if (x.changelogEntryType === ChangelogEntryType.QUIT) {
            teamTimeMillis += currentMillis - lastJoinTime;
            lastJoinTime = 0;
        }
        else if (lastJoinTime === 0)
        {
            lastJoinTime = currentMillis;
        }
    });
    if (lastJoinTime > 0) {
        teamTimeMillis += today.getTime() - lastJoinTime;
    }
    const diff = new Date(Math.floor(teamTimeMillis));

    return { years: diff.getUTCFullYear() - 1970, months: diff.getUTCMonth(), days: diff.getUTCDate() - 1 };
}

function toTitleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
