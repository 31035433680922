export enum RangGroup {
    Owner,
    Admin,
    Entwickelnde,
    Moderierende,
    Supportende,
    Contents,
    Architekten,
    Spielende
}

export function toRangDisplayTitle(rang: RangGroup | null) {
    if (rang == null) {
        return "???";
    }
    switch (rang) {
        case RangGroup.Owner:
            return "Owner";
        case RangGroup.Admin:
            return "Admin";
        case RangGroup.Entwickelnde:
            return "Entwickler/in";
        case RangGroup.Moderierende:
            return "Moderator/in";
        case RangGroup.Supportende:
            return "Supporter/in";
        case RangGroup.Contents:
            return "Content/in";
        case RangGroup.Architekten:
            return "Architekt/in";
        case RangGroup.Spielende:
            return "Spieler/in";
        default:
            return RangGroup[rang];
    }
}
